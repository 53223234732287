import React, { Component, SyntheticEvent } from 'react';
import { Search, SearchProps, SearchResultProps } from 'semantic-ui-react';
import { withScriptjs, WithScriptjsProps } from 'react-google-maps';
import _ from 'lodash';
import { Address, Coordinates } from '../../types';

export type AutocompleteAddressInputProps =
    & OwnProps
    & WithScriptjsProps;

interface StateProps {
    predictionOptions: SearchResultProps[]
}

interface OwnProps {
    onChange: (address: Address, coordinates: Coordinates) => void;
}

class AutocompleteAddressInput extends Component<AutocompleteAddressInputProps, StateProps> {
    constructor(props: AutocompleteAddressInputProps) {
        super(props);

        this.state = {
            predictionOptions: []
        };
    }

    autocompleteService = new google.maps.places.AutocompleteService();
    geocodeService = new google.maps.Geocoder();

    handleSearchChange = (_e: SyntheticEvent<HTMLElement, Event>, data: SearchProps) => {
        if (!_.isEmpty(data.value?.trim())) {
            this.autocompleteService.getPlacePredictions({
                input: data.value || '',
                componentRestrictions: {
                    'country': ['us', 'ca'],
                }
            }, this.handleAutocompleteResult);
        }
    };

    handleAutocompleteResult = (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            const predictionOptions = predictions.map((pred, index) => ({
                key: index,
                title: pred.structured_formatting.main_text,
                description: pred.structured_formatting.secondary_text,
            }));

            this.setState({ predictionOptions: predictionOptions });
        }
    };

    onAddressChange = (address: string, city: string) => {
        this.geocodeService.geocode({ address: `${address}, ${city}` }, (results, status) => {
            if (status === "OK") {
                const latlng = results[0].geometry.location.toJSON();
                const postalCode = results[0].address_components.find(x => x.types.find(p => p === 'postal_code'));
                const cityName = results[0].address_components.find(x => x.types.find(p => p === 'locality'));
                const province = results[0].address_components.find(x => x.types.find(p => p === 'administrative_area_level_1'));
                const country = results[0].address_components.find(x => x.types.find(p => p === 'country'));
                const number = results[0].address_components.find(x => x.types.find(p => p === 'street_number'));
                const route = results[0].address_components.find(x => x.types.find(p => p === 'route'));

                const address: Address = {
                    address1: `${number && number.long_name} ${route && route.long_name}`,
                    address2: '',
                    city: cityName ? cityName.long_name : city,
                    postalCode: postalCode ? postalCode.long_name : '',
                    province: province ? province.short_name : '',
                    country: country ? country.short_name : '',
                };

                this.props.onChange(address, { x: latlng.lng, y: latlng.lat });
            }
        });
    };

    render() {
        return (
            <Search
                fluid
                style={{ width: '100%' }}
                results={this.state.predictionOptions}
                onSearchChange={_.debounce(this.handleSearchChange, 300)}
                onResultSelect={(e, data) => this.onAddressChange(data.result.title, data.result.description)}
            />
        );
    }
}

const scriptComponent = withScriptjs(AutocompleteAddressInput);
export { scriptComponent as AutocompleteAddressInput };