import React from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { BranchEdit } from '../../models';
import { ValidationResult } from '../../../../utils/ValidationResult';
import { nameof } from '../../../../utils/nameof';
import { AutocompleteAddressInput } from './AutocompleteAddressInput';
import { getApiKey } from '../../../../utils/GoogleMapsUtils';
import { Address, Coordinates } from '../../types';

export interface BranchFormProps {
    branch: BranchEdit;
    validationResult: ValidationResult;
    onChange: (fieldId: string, fieldValue: any) => void;
    onAddressChange: (address: Address, coordinates: Coordinates) => void;
}

const m = defineMessages({
    emailLabel: { id: 'BranchForm.emailLabel', defaultMessage: 'Branch email' },
    phoneLabel: { id: 'BranchForm.phoneLabel', defaultMessage: 'Phone' },
    addressLabel: { id: 'BranchForm.addressLabel', defaultMessage: 'Address' }
});

export const BranchForm: React.FC<BranchFormProps> = (props) => {
    const { formatMessage } = useIntl();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const fieldId = event.currentTarget.id;
        const fieldValue = data.value;

        props.onChange(fieldId, fieldValue);
    };

    const handleAddressChange = (address: Address, coordinates: Coordinates) => {
        props.onAddressChange(address, coordinates);
    };

    return (
        <Form error={!props.validationResult.isValid}>
            <Form.Input
                id={nameof<BranchEdit>('email')}
                inputMode="email"
                error={props.validationResult.isFieldInvalid(nameof<BranchEdit>('email'))}
                required
                label={formatMessage(m.emailLabel)}
                value={props.branch.email || ''}
                onChange={handleInputChange}
            />

            <Form.Input
                id={nameof<BranchEdit>('phone')}
                inputMode="tel"
                error={props.validationResult.isFieldInvalid(nameof<BranchEdit>('phone'))}
                required
                label={formatMessage(m.phoneLabel)}
                value={props.branch.phone || ''}
                onChange={handleInputChange}
            />

            <Form.Field required error={props.validationResult.isFieldInvalid(nameof<BranchEdit>('address'))}>
                <label>{formatMessage(m.addressLabel)}</label>
                <AutocompleteAddressInput
                    onChange={handleAddressChange}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${getApiKey()}&libraries=places`}
                    loadingElement={<div style={{ height: '100%' }} />}
                />
            </Form.Field>
        </Form>
    );
};