import dayjs from "dayjs";
import { useCallback, useMemo } from "react";

export const useReportDates = () => {
    const currentMonth = dayjs().month();
    const reportLastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const reportLastYear = dayjs().year();

    const reportMonthsInOrder = useMemo(() => {
        const months: number[] = [];
        for (let i = 0, month = reportLastMonth; i < 12; i++) {
            months.unshift(month);
            month = month ? month - 1 : 11;
        }
        return months;
    }, [reportLastMonth]);

    const previousPeriodStartDate = useMemo(
        () => dayjs.utc().startOf("month").add(-24, "month").toDate(),
        []
    );

    const currentPeriodStartDate = useMemo(
        () => dayjs.utc(previousPeriodStartDate).add(12, "month").toDate(),
        [previousPeriodStartDate]
    );

    const previousPeriodEndDate = useMemo(
        () => dayjs.utc(currentPeriodStartDate).add(-1, "day").endOf("day").toDate(),
        [currentPeriodStartDate]
    );

    const currentPeriodEndDate = useMemo(
        () => dayjs.utc().add(-1, "month").endOf("month").toDate(),
        []
    );

    const getCurrentPeriodYear = useCallback(
        (month: number) => (month > reportLastMonth ? reportLastYear - 1 : reportLastYear),
        [reportLastMonth, reportLastYear]
    );

    const getPreviousPeriodYear = useCallback(
        (month: number) => (month > reportLastMonth ? reportLastYear - 2 : reportLastYear - 1),
        [reportLastMonth, reportLastYear]
    );

    const isCurrentPeriod = useCallback(
        (month: number, year: number) => getCurrentPeriodYear(month) === year,
        [getCurrentPeriodYear]
    );

    const isPreviousPeriod = useCallback(
        (month: number, year: number) => getPreviousPeriodYear(month) === year,
        [getPreviousPeriodYear]
    );

    return {
        reportMonthsInOrder,
        currentPeriodStartDate,
        currentPeriodEndDate,
        previousPeriodStartDate,
        previousPeriodEndDate,
        getCurrentPeriodYear,
        getPreviousPeriodYear,
        isCurrentPeriod,
        isPreviousPeriod,
    };
};
