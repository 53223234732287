import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FluidPageLayout } from '../../../components/layouts';
import { FloatingActionButtons, FloatingActionButton } from '../../../components/floatingActionButton';
import { Segment, Message } from 'semantic-ui-react';
import { BranchForm } from './components/BranchForm';
import { ClientForm } from './components/ClientForm';
import { defineMessages, useIntl } from 'react-intl';
import { ClientType, Address, Coordinates } from '../types';
import { BranchEdit, ClientEdit } from '../models';
import { ValidationResult } from '../../../utils/ValidationResult';
import * as ClientsActions from '../actions';
import * as LayoutActions from '../../layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClients, areClientsLoading, isSavingClient } from '../selectors';

const m = defineMessages({
    pageTitle: { id: 'CreateClientPage.pageTitle', defaultMessage: 'Create a new account' },
    branchDetailsTitle: { id: 'CreateClientPage.branchDetailsTitle', defaultMessage: 'Branch details' },
});

export const CreateClientPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const defaultClientType = location.pathname.startsWith("/leads") ? ClientType.Lead : ClientType.Client;

    const [client, setClient] = useState(new ClientEdit({ type: defaultClientType }));
    const [clientValidation, setClientValidation] = useState(ValidationResult.empty());
    const [branch, setBranch] = useState(new BranchEdit());
    const [branchValidation, setBranchValidation] = useState(ValidationResult.empty());
    const allClients = useSelector(getAllClients);
    const isLoadingClients = useSelector(areClientsLoading);
    const isSaving = useSelector(isSavingClient);

    const hasErrors = !clientValidation.isValid || !branchValidation.isValid;
    const localizedErrorMessages = clientValidation.localizeErrorMessages(formatMessage).concat(branchValidation.localizeErrorMessages(formatMessage));

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));
    }, [dispatch, formatMessage]);

    const navigateBack = () => navigate(-1);

    const save = () => {
        const isNewClient = client.clientId == null || client.clientId === 0;
        if (isNewClient) {
            client.branch = branch;

            const clientValidation = client.validate();
            setClientValidation(clientValidation);

            const branchValidation = branch.validate();
            setBranchValidation(branchValidation);

            if (clientValidation.isValid && branchValidation.isValid) {
                dispatch(ClientsActions.createClient(client));
            }
        } else {
            const branchValidation = branch.validate();
            setBranchValidation(branchValidation);

            if (branchValidation.isValid && client.clientId != null) {
                dispatch(ClientsActions.createBranch(client.clientId, branch));
            }
        }
    };

    const changeSelectedClient = (clientId: number | undefined, clientName: string | undefined) => {
        const targetClient = allClients.find(x => x.id === clientId);

        setClient(new ClientEdit({
            ...client,
            clientId: clientId,
            displayName: clientName || targetClient?.displayName,
            ownerEmail: targetClient?.ownerEmail || '',
            type: targetClient?.type != null ? targetClient.type : client.type,
        }));
    };

    const updateClient = (fieldId: string, fieldValue: any) => {
        setClient(new ClientEdit({
            ...client,
            [fieldId]: fieldValue
        }));
    };

    const updateBranch = (fieldId: string, fieldValue: any) => {
        setBranch(new BranchEdit({
            ...branch,
            [fieldId]: fieldValue
        }));
    };

    const updateBranchAddress = (address: Address, coordinates: Coordinates) => {
        setBranch(new BranchEdit({
            ...branch,
            address: address,
            coordinates: coordinates,
        }));
    };

    return (
        <FluidPageLayout>
            <ClientForm
                client={client}
                validationResult={clientValidation}
                allClients={allClients}
                loading={isLoadingClients}
                onChange={updateClient}
                onClientChange={changeSelectedClient}
            />

            <h2>{formatMessage(m.branchDetailsTitle)}</h2>
            <Segment>
                <BranchForm
                    branch={branch}
                    validationResult={branchValidation}
                    onChange={updateBranch}
                    onAddressChange={updateBranchAddress}
                />
            </Segment>

            {hasErrors && <Message error list={localizedErrorMessages} />}

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={navigateBack}
                />
                <FloatingActionButton
                    disabled={isSaving}
                    loading={isSaving}
                    primary
                    icon="save"
                    onClick={save}
                />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};