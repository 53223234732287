import React, { Component } from "react";
import {
    withScriptjs,
    WithScriptjsProps,
    withGoogleMap,
    WithGoogleMapProps,
    GoogleMap,
    Marker,
} from "react-google-maps";
import { Branch } from "../modules/clients/types";

export type GoogleMapComponentProps = OwnProps & WithScriptjsProps & WithGoogleMapProps;

interface OwnProps {
    branchList: Branch[];
    defaultCenter?: google.maps.LatLngLiteral;
}

class GoogleMapComponent extends Component<GoogleMapComponentProps> {
    branches = () => {
        return this.props.branchList.filter(
            (b) => b.coordinates && b.coordinates.x && b.coordinates.y
        );
    };

    render() {
        return (
            <>
                <GoogleMap
                    zoom={8}
                    defaultCenter={this.props.defaultCenter || { lat: 45.702556, lng: -73.62413 }}
                    defaultOptions={{
                        streetViewControl: false,
                        fullscreenControl: false,
                        mapTypeControl: false,
                        zoomControl: false,
                    }}
                >
                    <>
                        {this.branches().map((branch) => (
                                <Marker
                                    key={branch.id}
                                    position={{
                                        lat: branch.coordinates.y,
                                        lng: branch.coordinates.x,
                                    }}
                                />
                            ))}
                    </>
                </GoogleMap>
            </>
        );
    }
}

const withGoogleMapComponent = withGoogleMap<GoogleMapComponentProps>(GoogleMapComponent);
const withScript = withScriptjs<GoogleMapComponentProps>(withGoogleMapComponent);
export default withScript;
