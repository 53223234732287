import React, { useEffect, useState } from 'react';
import { ClientLayout } from './components/ClientLayout';
import { Segment, Input, Divider, InputOnChangeData } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { ClientTab } from './components/ClientTab';
import { Client, ClientType } from '../types';
import GoogleMapComponent from '../../../components/GoogleMapComponent';
import { getApiKey } from '../../../utils/GoogleMapsUtils';
import _ from 'lodash';
import { ClientList } from './components/ClientList';
import { FloatingActionMenu, FloatingActionMenuItem } from '../../../components/floatingActionButton';
import { useLocation } from 'react-router-dom';
import styles from './ClientsPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClients, areClientsLoading } from '../selectors';
import * as LayoutActions from '../../layout/actions';

const m = defineMessages({
    title: { id: 'ClientsPage.title', defaultMessage: 'Clients' },
    newClientAction: { id: 'ClientsPage.newClientAction', defaultMessage: 'New client' },
    newBranchAction: { id: 'ClientsPage.newBranchAction', defaultMessage: 'New branch' },
    newLeadAction: { id: 'ClientsPage.newLeadAction', defaultMessage: 'New lead' },
    searchPlaceholder: { id: 'ClientsPage.searchPlaceholder', defaultMessage: 'Search' },
    noResults: { id: 'ClientsPage.noResults', defaultMessage: 'No results were found.' },
    clearSearch: { id: 'ClientsPage.clearSearch', defaultMessage: 'Clear query' },
});

const maxResults = 10;

export const ClientsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState<Client[]>([]);


    const clearSearch = () => setSearchText('');
    const handleSearch = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setSearchText(data.value);
    const clientFilter = location.pathname.endsWith("leads") ? ClientType.Lead : ClientType.Client;

    const allClients = useSelector(getAllClients);
    const loading = useSelector(areClientsLoading);

    const hasSearchResults = loading || searchResults.length > 0;

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        if (_.isEmpty(searchText)) {
            setSearchResults(_(allClients).filter(x => x.type === clientFilter).take(maxResults).value());
            return;
        }

        const deburrSearchText = _.deburr(searchText).toLocaleLowerCase();
        const results = _(allClients)
            .filter(x => x.type === clientFilter
                && (x.displayName.toLocaleLowerCase().includes(deburrSearchText)
                    || x.branches?.find(x => x.city.toLocaleLowerCase().includes(deburrSearchText)
                        || x.address.province.toLocaleLowerCase().includes(deburrSearchText)
                        || x.phone.toLocaleLowerCase().includes(deburrSearchText)
                        || x.zipCode.toLocaleLowerCase().includes(deburrSearchText)) != null)
            )
            .take(maxResults)
            .value();

        setSearchResults(results);

    }, [allClients, searchText, location, clientFilter]);

    return (
        <ClientLayout>
            <ClientTab />

            <Segment style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
                <div>
                    <Input
                        fluid
                        inputMode="search"
                        disabled={loading}
                        loading={loading}
                        icon="search"
                        value={searchText}
                        placeholder={formatMessage(m.searchPlaceholder)}
                        onChange={handleSearch}
                    />
                    <Divider />
                </div>
                <div className={styles.searchResults}>
                    {hasSearchResults &&
                        <ClientList
                            clients={searchResults}
                            loading={loading}
                        />
                    }
                    {!hasSearchResults &&
                        <div className={styles.noSearchResults}>
                            <p>{formatMessage(m.noResults)}</p>
                            <a onClick={clearSearch}>{formatMessage(m.clearSearch)}</a>
                        </div>
                    }
                </div>

                <Divider />
                <div className={styles.mappedResults}>
                    <GoogleMapComponent
                        branchList={_(searchResults).flatMap(x => x.branches).filter(x => x.coordinates != null).value()}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${getApiKey()}&libraries=places`}
                        loadingElement={<div style={{ height: '100%' }} />}
                        containerElement={<div style={{ flex: 1 }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                    />
                </div>
            </Segment>

            <FloatingActionMenu>
                <FloatingActionMenuItem to="/leads/new" icon="user outline" title={formatMessage(m.newLeadAction)} />
                <FloatingActionMenuItem to="/clients/new" icon="user" title={formatMessage(m.newClientAction)} />
                <FloatingActionMenuItem to="/clients/new/branch" icon="sitemap" title={formatMessage(m.newBranchAction)} />
            </FloatingActionMenu>
        </ClientLayout>
    );
};